<script lang="ts" setup>
const { status } = useAuth()
const { canAccessPage } = useAuthorization()
const isMobileScreen = useIsMobileScreen()

const { cartId } = useShopCart()
const { cartQuery } = useShopQuery()
const { data } = cartQuery.getById(cartId, computed(() => status.value === 'authenticated'))
</script>

<template>
  <div class="sticky top-0 w-full bg-hos-blue-dark py-4 border-b border-white/10 z-30">
    <div class="flex items-center gap-4 justify-between">
      <NuxtLink to="/shop">
        <img src="/branding/Logo-Hanselmann-ohne Slogan_weiss.png" class="h-5 sm:h-8">
      </NuxtLink>

      <div class="flex items-center gap-2">
        <CustomerModuleLayoutLanguageSelector :display-text="!isMobileScreen" />

        <!-- Regular authenticated user -->
        <template v-if="status === 'authenticated'">
          <CustomerModuleLayoutUserDropdown />
        </template>

        <!-- Unauthenticated user -->
        <NuxtLink v-else to="/auth/login">
          <n-button type="primary">
            <template #icon>
              <Icon name="material-symbols:login" />
            </template>
            {{ $t('customerModule.navigation.login.title') }}
          </n-button>
        </NuxtLink>

        <NuxtLink v-if="canAccessPage('/shop/checkout')" to="/shop/checkout">
          <n-badge :value="data?.machineryVersions?.length ?? 0" type="info" processing>
            <n-button type="primary">
              <template #icon>
                <Icon name="material-symbols:shopping-cart-outline-rounded" />
              </template>
              <template v-if="!isMobileScreen">
                {{ $t('shop.checkout.cart.title') }}
              </template>
            </n-button>
          </n-badge>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
